






















import Vue from "vue";
// import { defineComponent } from "vue";
import eventHub from "@/event-hub";
import { a11yFixBuefyNotificationAriaAttrs } from "@/services/a11y";
import store from "@/store";
import router from "@/router";
import { isAfter, isBefore, isValid } from "date-fns";
import {
    SiteOptions
} from "@/types/interfaces";

export default Vue.extend({
    name: "HandleRedirects",
    directives: {
        addbuefynotification: {
            bind: function(el: HTMLElement) {
                eventHub.$emit("BuefyNotificationAppUpdatesBound", el);
            }
        }
    },
    data() {
        return {
            validInterval: -1,
            updatesAreAvailable: false,
            dismissedAlerts: [] as Array<string>,
            checkDateTimeToRedirect: false,
            duration: 12000,
            progressBar: true
        };
    },
    computed: {
        options(): any {
           return store.getters.getPageOptions("redirects") ? store.getters.getPageOptions("redirects") : undefined ;
        },
        route(): any {
            return store.state.route || {};
        },
        displayNotification(): boolean {
            // TODO
            // speakeasies are currently buggy with direct browse
            // so we dont want to encourage page refreshes in speakeasies
            const disableOnRoutes = ["MeetingView"];
            const routeIsAllowed = !disableOnRoutes.includes(this.route.name);

            return routeIsAllowed;
        },
        allowedRedirectPage(): boolean {
            return this.route.fullPath != this.options.urlToRedirect ? true : false
        }
        // checkDateTimeToRedirect():  boolean {
        //     const isoDateString = '2023-03-15T19:45:00.000Z';
        //     const date = new Date(isoDateString);

        //     const localDateString = date.toLocaleDateString(undefined, {
        //         year: "numeric",
        //         month: "numeric",
        //         day: "numeric",
        //         hour: "numeric",
        //         minute: "numeric"
        //     });

        //     const currentDate = new Date();
        //     const specificDate = new Date(localDateString);

        //     return currentDate >= specificDate
        //     // return specificDate
        // },
        // dateToday(): any {
        //     return new Date();
        // }
        
    },
    created() {
        eventHub.$on(
            "BuefyNotificationAppUpdatesBound",
            this.handleBuefyNotificationFixes
        );
        this.getDismissedAlerts();
    },
    beforeDestroy() {
        eventHub.$off(
            "BuefyNotificationAppUpdatesBound",
            this.handleBuefyNotificationFixes
        );

        window.clearInterval(this.validInterval);
    },
    mounted(){
        this.validInterval = window.setInterval(() => {
            if(!this.options) {
                this.stopInterval();
                return
            }
            const reRouteTo = this.options.urlToRedirect;
            const redirectToIsoDateString = new Date(this.options.dateTimeToRedirect);
            const stopRedirectToIsoDateString = new Date(this.options.dateTimeToStopRedirect);

            const localDateString = redirectToIsoDateString.toLocaleDateString(undefined, {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric"
            });

            const stopRedirectToLocalDateString = stopRedirectToIsoDateString.toLocaleDateString(undefined, {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric"
            });

            const currentDate = new Date();
            const specificDate = new Date(localDateString);
            const stopRedirectDate = new Date(stopRedirectToLocalDateString);

            
            if ((currentDate >= specificDate && currentDate < stopRedirectDate)) {
                if(this.allowedRedirectPage){
                    this.checkDateTimeToRedirect = true;
                    setTimeout(()=> {
                        router.push(reRouteTo);
                    },8000);
                }
          
                this.stopInterval();
            }

            if(currentDate > stopRedirectDate || this.options.dateTimeToRedirect === "") {
                this.stopInterval();
            }
            
        }, 1000);
    },
    methods: {
        stopInterval() {
            window.clearInterval(this.validInterval);
        },
        handleBuefyNotificationFixes(el: HTMLElement): void {
            a11yFixBuefyNotificationAriaAttrs(el);
        },
        getDismissedAlerts(): void {
            const alertData = localStorage.getItem("dismissedAlerts");

            this.dismissedAlerts = alertData ? JSON.parse(alertData) : [];
        },
        handleDismiss(id: string) {
            this.dismissedAlerts.push(id);
            localStorage.setItem(
                "dismissedAlerts",
                JSON.stringify(this.dismissedAlerts)
            );
        }
    }
});
