import Vue from "vue";
import "./vue-global.js";

import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import Rollbar from "rollbar";
import { sync } from "vuex-router-sync";
import { SessionDataObject } from "@/types/interfaces";
import ScrollAnimation from "./directives/ScrollAnimation";
import LazyLoad from "./directives/LazyLoad";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faGoogle, faYahoo } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import Oruga from "@oruga-ui/oruga";

// plugin styles
// import "swiper/css/swiper.css";

// main styles
// import "./styles/global.scss";

// utility styles
// import "./css/tailwind.css";

/**
 * ICON NOTE:
 * Use the `font-awesome-icon` component to display icons
 * https://fontawesome.com/v5/search?q=person&m=free
 *
 * Example:
 * <font-awesome-icon icon="comment"/>
 *
 * DO NOT use dom.watch();
 */

library.add(fas, faGoogle, faYahoo);

// plugin styles
import "./css/tailwind-base.css";
/**
 * The Oruga UI file is a copy of ~@oruga-ui/oruga/dist/oruga-full-vars.css.
 * I had to pull it out because the build process splits off the oruga CSS into a vendor chunk,
 * which gets loaded before all of the rest of this, which then ends up getting reset by Tailwind's
 * CSS reset.
 *
 * I don't want to remove Tailwind's reset since it might affect what the other styles look like,
 * so unfortunately this is the best solution for now.
 *
 * If you update the Oruga UI package you should update the CSS file with the new CSS as well.
 */
import "./css/oruga-ui.css";

// main styles
import "./styles/global.scss";

// utility styles
import "./css/tailwind.css";
import "./css/fix-tailwind-conflicts.css";

sync(store, router);
// const unsync = sync(store, router); // done. Returns an unsync callback fn.
// unsync() // Unsyncs store from router

// TODO: get rid of font-awesome-icon when we're fully converted to oruga from buefy
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.directive("scrollanimation", ScrollAnimation);
Vue.directive("lazyload", LazyLoad);

Vue.use(Oruga, {
    iconComponent: "font-awesome-icon",
    iconPack: "fas"
});

Vue.prototype.$rollbar = new Rollbar({
    accessToken: "ddddca3adfd647b49da490fc741a4685",
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true,
    environment: "production"
});

Vue.config.productionTip = false;

interface ThemeConfigInterface {
    sponsorVideos: Record<string, any>;
}

function handleThemeConfig(themeConfig: null | ThemeConfigInterface) {
    if (!themeConfig) return;

    if (Array.isArray(themeConfig.sponsorVideos)) {
        themeConfig.sponsorVideos = themeConfig.sponsorVideos.map(
            (videoObject: SessionDataObject) => {
                videoObject.isSponsorVideo = true;
                return videoObject;
            }
        );
    }

    store.commit("setThemeConfig", themeConfig);
    store.commit("setThemeConfigLoaded", true);
    store.commit("setCSSvariables");
}

// Set up from client config
let themeConfig = null;
const client = process.env.VUE_APP_MG_CLIENT;

// empty promise to resolve if we aren't using axios to get config
let configPromise = Promise.resolve();

if (client) {
    themeConfig = require(`./sites/${client}/config.json`);
    handleThemeConfig(themeConfig);
} else {
    configPromise = axios
        .get(`/config.json?t=${new Date().getTime()}`)
        .then((response) => {
            handleThemeConfig(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
}

function injectFontStylesheet(srcArray?: Array<string>) {
    if (Array.isArray(srcArray) && srcArray.length) {
        srcArray.forEach((src) => {
            const css = document.createElement("link");
            css.href = src;
            css.rel = "stylesheet";
            document.head.append(css);
        });
    }
}

console.log("environment:", process.env.NODE_ENV);

configPromise.then(async () => {
    injectFontStylesheet(store.getters.webfontSrc);

    const tokenString = localStorage.getItem("token") || "{}";
    await store
        .dispatch("loginUser", JSON.parse(tokenString))
        .catch((error) => {
            error;
        })
        .finally(() => {
            new Vue({
                router,
                store,
                render: (h) => h(App)
            }).$mount("#app");
        });
});
